<template>
  <div class="task-container">
    <h2 class="task-heading">{{headingText}}
      <i
        class="ml-1"
        :class="{'rotate-icon': !isFinished, 'fa fa-cog': !isFinished, 'fa fa-check': isFinished}"
    ></i>
    </h2>
    <div class="progress-text">
      Status:
        <span :class="{'progress-failed': statusText==='Failed'}">{{statusText}}</span>
    </div>
    <div>
      <b-progress :value="progressValue" :max="progressMax"></b-progress>
    </div>
  </div>
</template>
<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'

export default {
  TASK_STATES: {
    NEW: 'NEW',
    QUEUED: 'QUEUED',
    INPROG: 'INPROG',
    COMPLETE: 'COMPLETE',
    FAILED: 'FAILED'
  },
  name: 'TaskProgress',
  props: {
    task_id: Number,
    title: String
  },
  data () {
    return {
      taskStatusNames: {
        NEW: 'Created',
        QUEUED: 'Queued',
        INPROG: 'Processing',
        COMPLETE: 'Complete',
        FAILED: 'Failed'
      },
      finishedStates: [
        'COMPLETE',
        'FAILED'
      ],
      taskData: {},
      taskInterval: null,
      progressMax: 3
    }
  },
  mounted () {
    this.refreshTask()
    this.startInterval()
  },
  beforeDestroy () {
    this.stopRefreshing()
  },
  methods: {
    startInterval () {
      this.taskInterval = setInterval(() => {
        this.refreshTask()
        if (Object.hasOwn(this.taskData, 'status') && this.finishedStates.includes(this.taskData.status)) {
          this.$emit('finish', this.taskData.status, this.taskData.data)
          this.stopRefreshing()
        }
      }, 1000)
    },
    stopRefreshing () {
      // Cleanup the interval!
      clearInterval(this.taskInterval)
    },
    async refreshTask () {
      let resp = await DataProvider.getTask(this.task_id)
      if (resp.success) {
        this.taskData = resp.data
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    }
  },
  computed: {
    statusText () {
      if (Object.hasOwn(this.taskData, 'status')) {
        if (Object.hasOwn(this.taskStatusNames, this.taskData.status)) {
          return this.taskStatusNames[this.taskData.status]
        } else {
          return `Unknown (${this.taskData.status})`
        }
      } else {
        return 'Created'
      }
    },
    headingText () {
      if (this.title) {
        return this.title
      } else {
        return 'Processing Task'
      }
    },
    progressValue () {
      if (Object.hasOwn(this.taskData, 'status')) {
        return Object.keys(this.taskStatusNames).indexOf(this.taskData.status)
      } else {
        return 0
      }
    },
    isFinished () {
      if (Object.hasOwn(this.taskData, 'status')) {
        return this.finishedStates.includes(this.taskData.status)
      } else {
        return false
      }
    }
  },
  watch: {
    task_id (newVal) {
      this.refreshTask()
      this.startInterval()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
@import '@/custom';
.task-container {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.task-heading {
  color: $theme-color-primary-3;
}

.progress-text {
  color: $theme-color-primary-2;
}

.progress-failed {
  color: #800000;
}

</style>
