var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "task-container" }, [
    _c("h2", { staticClass: "task-heading" }, [
      _vm._v(_vm._s(_vm.headingText) + " "),
      _c("i", {
        staticClass: "ml-1",
        class: {
          "rotate-icon": !_vm.isFinished,
          "fa fa-cog": !_vm.isFinished,
          "fa fa-check": _vm.isFinished
        }
      })
    ]),
    _c("div", { staticClass: "progress-text" }, [
      _vm._v(" Status: "),
      _c(
        "span",
        { class: { "progress-failed": _vm.statusText === "Failed" } },
        [_vm._v(_vm._s(_vm.statusText))]
      )
    ]),
    _c(
      "div",
      [
        _c("b-progress", {
          attrs: { value: _vm.progressValue, max: _vm.progressMax }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }